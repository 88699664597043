import React,{useState, useEffect} from 'react';
import './Style.css';
import './Style.scss';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Login from './Component/Login/Login';
import Home from './Component/Home';
import History from './Component/Page/History';
import Wallet from './Component/Page/Wallet';
import Play from './Component/Page/Play';
import Help from './Component/Page/Help';
import ReferCode from './Component/Page/ReferCode';
import Jodi from './Component/Page/Jodi';
import Register from './Component/Login/Register';
import Manual from './Component/Page/Manual';
import Playgame from './Component/Page/Playgame';
import Harruf from './Component/Page/Harruf';
import Crossing from './Component/Page/Crossing';
import Playcommon from './Component/Page/Playcommon';
import Copypaste from './Component/Page/Copypaste';
import Profile from './Component/Page/Profile';
import Resulthistory from './Component/Page/Resulthistory';
import Termsandcondition from './Component/Page/Termsandcondition';
import Notification from './Component/Page/Notification';
import Appdetails from './Component/Page/Appdetails';
import Gameposting from './Component/Page/Gameposting';
import Bonusreport from './Component/Page/Bonusreport';
import Withdrawalchat from './Component/Page/Withdrawalchat';
import Depositchat from './Component/Page/Depositchat';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '.././src/assets/fonts/font1/stylesheet.css';
import '.././src/assets/fonts/font-2/stylesheet.css';
import Header from ".././src/Component/Header/Header";
import Footer from ".././src/Component/Footer";
import { isMobile } from 'react-device-detect';




function App() {
  const [status, setStatus] = useState('');
console.warn(status)
  const clearApplicationCache = async () => {
    try {
      const cacheNames = await caches.keys(); 
      await Promise.all(
        cacheNames.map(cacheName => caches.delete(cacheName))
      );
      setStatus('Application cache cleared successfully.');
    } catch (error) {
      setStatus('Error clearing application cache.');
      console.error('Error clearing application cache:', error);
    }
  };
  useEffect(() => {
    clearApplicationCache();
  }, []); 
  return (
    <>
      {/* {isMobile && */}
        <BrowserRouter status>
          <Routes>
            <Route
              path="/*"
              element={
                <div>
                  <Header />
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/Wallet" element={<Wallet />} />
                    <Route path="/History" element={<History />} />
                    <Route path="/Play" element={<Play />} />
                    <Route path="/Playgame" element={<Playgame />} />
                    <Route path="/Playcommon" element={<Playcommon />} />
                    <Route path="/Notification" element={<Notification />} />
                    <Route path="/Help" element={<Help />} />
                    <Route path="/Jodi" element={<Jodi />} />
                    <Route path="/Manual" element={<Manual />} />
                    <Route path="/Harruf" element={<Harruf />} />
                    <Route path="/Crossing" element={<Crossing />} />
                    <Route path="/Copypaste" element={<Copypaste />} />
                    <Route path="/Profile" element={<Profile />} />
                    <Route path="/Resulthistory" element={<Resulthistory />} />
                    <Route path="/Appdetails" element={<Appdetails />} />
                    <Route path="/Termsandcondition" element={<Termsandcondition />} />
                    <Route path="/Bonusreport" element={<Bonusreport />} />
                    <Route path="/ReferCode" element={<ReferCode />} />


                  </Routes>
                  <Footer />
                </div>
              }
            />
            <Route path="/Withdrawalchat" element={<Withdrawalchat />} />
            <Route path="/Depositchat" element={<Depositchat />} />
            <Route path="/Gameposting" element={<Gameposting />} />
            <Route path="/Register" element={<Register />} />
            <Route path="/Playgame" element={<><Playgame /><Header /> </>} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </BrowserRouter>

      {/* } */}
    </>
  );
}

export default App;
