import * as Icon from "react-bootstrap-icons";

const user_id = localStorage.getItem("userid");

 const Sidebardata = [
  {
    title: "Profile",
    path: "/Profile",
    icon: <Icon.PersonCircle />,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "App Commission",
    path: "/Appdetails",
    icon: <Icon.Phone />,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Game History",
    path: "/History",
    icon: <Icon.ClockHistory />,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Result History",
    path: "/Resulthistory",
    icon: <Icon.HourglassSplit />,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Terms And Condition",
    path: "/Termsandcondition",
    icon: <Icon.JournalCheck />,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Help",
    path: "tel:+9772535706",
    icon: <Icon.Phone />,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
  {
    title: "Logout",
    // path:"/Login",
    icon: <Icon.BoxArrowLeft />,
    ownclass: "d-flex align-items-center link-page navlink-design",
  },
];


export default Sidebardata;