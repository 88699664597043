import React ,{ useState,useEffect }from 'react'
import jQuery from 'jquery';
import { Link } from 'react-router-dom';
import Play from './Page/Play';
import Wallet from './Page/Wallet';
// import chat from '../assets/img/sodapdf-converted.gif'
import Whatsapp from '../assets/img/whatsappicon.png'
import axios from "axios";

export default function Footer() {
  const [userData, setUserData] = useState(null);

  const handleReload = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    
    loaduser1();
   
  }, [])
  const loaduser1 = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    // alert(dev_id);
    let url = (`${process.env.REACT_APP_API_URL}/POM_get_user_profile.php`);
    // console.warn(url);
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    formData.append('dev_id', dev_id);
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        // alert(res)
        const objectRes = JSON.parse(res);
        setUserData(objectRes);
        console.warn(objectRes);
      })
  }
  jQuery(function () {
    jQuery('.menu .iconmenu').click(function () {
      jQuery('.iconmenu').removeClass("activemenu");
      jQuery(this).addClass("activemenu");


    });
    jQuery(window).scroll(function () {
      if (jQuery(this).scrollTop() > 50) {
        jQuery('.footer-menu').addClass('footerscroll');
      } else {
        jQuery('.footer-menu').removeClass('footerscroll');
      }
    });
  });

  return (

    <>

      <a href='https://api.whatsapp.com/send?phone=9358178480'>
        {/* <div className="chaticon">
        <img src={Whatsapp}/>
   </div> */}

      </a>
      <div className="footer-menu">
        <div className="menufooter">
          {userData && userData.is_playstore == 0 ?
          <ul>
            <></>
            <li className="menu ">
              <div className='iconmenu activemenu'>
                <i class="bi bi-house-door-fill"></i>
                <Link to="/" onClick={handleReload} className='buttonpage' >
                  Home
                </Link>
              </div>
            </li>
            <li className="menu">
              <div className='iconmenu'>
                <i class="bi bi-controller"></i>
                <Link to="/Play" onClick={handleReload} className='buttonpage'>
                  Play</Link>
              </div>
            </li>
            <li className="menu">
              <div className='iconmenu'>
                <i class="bi bi-wallet2"></i>
                <Link to="/wallet" onClick={handleReload} className='buttonpage'>
                  Wallet</Link>
              </div>
            </li>

            <li className="menu">
              <div className='iconmenu'>
                  <i class="bi bi-joystick"></i>
                <Link to="/History" className='buttonpage'>
                  My Game </Link>
              </div>
            </li>
          </ul>
            :
            <></>
          }
        </div>
      </div>
    </>
  )
}
