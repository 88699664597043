import React, { useState, useEffect } from 'react';
import Logoimg from '../../assets/img/logo.png';
import { LockOutlined, UserOutlined, MobileOutlined } from '@ant-design/icons';
import { Button, Form, Input, Row, Col } from 'antd';
import axios from "axios";
import { toast } from "react-toastify";
import Swal from 'sweetalert2';
import { ToastContainer } from "react-toastify";
import withReactContent from 'sweetalert2-react-content';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
// import { MdOutlinePhoneIphone } from "react-icons/md";

export default function Register() {

  const [isShown, setIsShown] = useState("false")
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [mobile, setMobile] = useState('');
  const [refercode, setRefercode] = useState('');
  const [refercode1, setRefercode1] = useState('');
  const [otp, setOtp] = useState(null);
  const [devid, SeTdevid] = useState('');
  const navigate = useNavigate();
  // const [otpnumbers, otpnumber] = useState();
  const onFinish = (values) => {
    console.log('Received values of form: ', values);
  };
  var token = localStorage.getItem("userid");
  const [browserId, setBrowserId] = useState('');


  useEffect(() => {

    const queryParameters = new URLSearchParams(window.location.search);
    const reffercode = queryParameters.get("reffercode");
    // alert(reffercode)
    setRefercode1(reffercode);
    let browserIdd = Cookies.get('browserId');
    //  alert(browserIdd)
    if (!browserIdd) {
      browserIdd = uuidv4();
      Cookies.set('browserId', browserIdd, { expires: 365 }); // Store the ID in a cookie that expires in 365 days
    }
    localStorage.setItem("dev_id", browserIdd);
    setBrowserId(browserIdd)


    // alert(setBrowserId);
    // console.log('Browser ID:', browserIdd);
  }, []);

  // useEffect(() => {
  //  if(token){
  //   window.location.href = '/Home';
  //  }
  // }, [])

  // const dev_id = localStorage.getItem("dev_id");
  const onInputChangename = (e) => {
    setName(e.target.value);
  };

  const onInputChangepassword = (e) => {
    setPassword(e.target.value);
  };

  const onInputChangemobile = (e) => {
    setMobile(e.target.value);
  };


  const [alternate_mobile, setAlternateMobile] = useState('');

  const handleChange = (e) => {
    let inputValue = e.target.value;
    // Remove any non-digit characters
    inputValue = inputValue.replace(/\D/g, '');
    setAlternateMobile(inputValue);
  };
  // const onInputChangerefercode = (e) => {
  //   setRefercode(e.target.value);
  // };
  const onInputChangerefercode = (newValue) => {
    setRefercode1(newValue);
  };

  const onInputChangeotp = (e) => {
    setOtp(e.target.value);
  };

  // const navigate = useNavigate();

  const Registerotp = async () => {


    // setLoading(true);
    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('pss', password);
      formData.append('otp', otp);
      formData.append('mobileNum', alternate_mobile);
      formData.append('refercode', refercode1);
      formData.append('dev_id', browserId);
      // formData.append('dev_id',devid );



      formData.append('app_id', process.env.REACT_APP_API_ID);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/POM_register.php`, formData);
      // console.warn(response.data.data);
      // alert(response.data.data.device_id);

      // console.warn(response.data.dev_id);
      // console.warn(response.data.success);
      if (response.data.success == 1) {
        setIsShown(true)
        SeTdevid(true)
        // setOtp(true)
        console.log()
        console.log(response.data);
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Login  successfully.',
          timer: 2000
        }).then((result) => {
          navigate('/');
          localStorage.setItem("userid", response.data.user_id);
          sessionStorage.setItem("user_id", response.data.user_id);
          // localStorage.setItem("dev_id", response.data.dev_id);
          // console.warn(response.data.dev_id);
          // alert(response.data.dev_id)
        })

      } else if (response.data.success == 0) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: response.data.message,
          timer: 2000
        }).then((result) => {
          // window.location.href = '/Home';
        })
      }

    } catch (error) {
      console.error('User Name Update:', error);
      console.error('An error occurred while updating username. Please try again later.');
      // setIsShown(false)

    }

  };
  const Sendotp = async () => {

    if (!name) {

      toast.error('Please enter a valid name address.');
      return;
    }
    // if (!refercode ) {

    //   toast.error('Please enter a valid refercode address.');
    //   return;
    // }

    if (!password || password.length < 6) {

      toast.error('Password must be at least 6 characters long.');
      return;
    }
    // if (!mobile || mobile.length < 10) {

    //   toast.error('Password must be at least 10 characters long.');
    //   return;
    // }
    // setLoading(true);
    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('pss', password);
      formData.append('mobileNum', alternate_mobile);
      // formData.append('refercode', refercode1) ;
      formData.append('lat', 'fdfd');
      formData.append('lng', 'fdfdf');
      formData.append('dev_id', browserId);
      formData.append('app_id', process.env.REACT_APP_API_ID);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/POM_registerstep1.php`, formData);
      // localStorage.setItem('dev_id', response.data.dev_id);
      // console.warn(response.data.dev_id);
      // console.warn(response.data.success);
      if (response.data.success == 1) {
        setIsShown(true)

        devid(true)
        // setOtp(true)
        console.log()
        console.log(response.data);
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Otp Send successfully.',
          timer: 2000
        }).then((result) => {
          localStorage.setItem('dev_id', response.data.device_id);
          sessionStorage.setItem('devid', response.data.device_id);
          console.warn(response.data.device_id);
          // window.location.href = '/Home';
        })

      } else if (response.data.success == 0) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: response.data.message,
          timer: 2000
        }).then((result) => {
          // window.location.href = '/Home';
        })
      }

    } catch (error) {
      console.error('User Name Update:', error);
      console.error('An error occurred while updating username. Please try again later.');
      // setIsShown(false)

    }

  };





  return (
    <>
      <section id="Register">
        <div className="register">
          <Row justify={'center'}>
            <Col xs={24} xl={8}>
              <div className="logo w-100 text-center">
                <img src={Logoimg} className="img-fluid" alt="Logo" style={{ width: "150px" }} />
              </div>

              <div className="formregister text-transform-uppercase">
                <Form
                  name="normal_login"
                  className=""
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                >
                  <Form.Item
                    name="name "
                    className='text-transform-uppercase'

                    rules={[
                      {
                        required: true,
                        message: 'Please input your Name!',
                      },
                    ]}
                  >
                    <Input

                      prefix={<UserOutlined className="site-form-item-icon " />}
                      placeholder="Name"
                      className='text-transform-uppercase'
                      style={{ textTransform: 'uppercase' }}
                      value={name}
                      onChange={onInputChangename}
                    />
                  </Form.Item>
                  {/* <Form.Item
                    name="Mobile"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Mobile number!',
                      },
                    ]}
                  >
                    <Input
                      prefix={<MobileOutlined className="site-form-item-icon" />}
                      type="text"
                      placeholder="Mobile Number"
                      value={mobile}
                      onChange={onInputChangemobile}
                    />

                   
                  </Form.Item> */}



                  <Form.Item
                    name="Mobile Number"

                  >
                    <Input

                      prefix={<MobileOutlined className="site-form-item-icon" />}
                      showCount maxLength={10} id="Alternativemobilenumber"
                      value={alternate_mobile}
                      onChange={handleChange}
                      type='tel'
                      // onKeyDown={(e) => {
                
                      //   if (e.key === 'Backspace') {
                      //     return;
                      //   }
                   
                      //   if (/^[0-9]$/.test(e.key)) {
                      //     return;
                      //   }
                      
                      //   e.preventDefault();
                      // }}
                      // onKeyPress={(e) => {
                    
                      //   if (!/^[0-9]$/.test(e.key) && e.key !== 'Backspace') {
                      //     e.preventDefault();
                      //   }
                      // }}
                      placeholder=" Mobile Number" />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Password!',
                      },
                    ]}
                  >
                    <Input
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={onInputChangepassword}
                    />
                  </Form.Item>

                  <Form.Item
                    name="Refer"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Please input your Refer Code!',
                  //   },
                  // ]}
                  >
                    {refercode}
                    <Input
                      prefix={<MobileOutlined className="site-form-item-icon" />}
                      type="text"
                      placeholder="Refer Code"
                      value={refercode1}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        onInputChangerefercode(newValue);

                        // Check if a new value is entered and clear accountno
                        if (newValue !== refercode1) {
                          setRefercode('');
                        }
                      }}
                    />

                  </Form.Item>
                  {isShown == true ? (
                    <Col span={24} xl={24} md={24}>
                      <Form.Item
                        name="otp"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your OTP!',
                          },
                        ]}
                      >

                        <Input style={{ padding: "9px" }} onChange={onInputChangeotp} placeholder="Enter OTP" />

                      </Form.Item>
                    </Col>
                  ) : null}



                  <Form.Item>
                    {isShown == true ? <button type="button" onClick={Registerotp} className="bg-green w-100 btn refer-button cxy  otp_login submit_data" id="submit_data" name="submit_data" >Register</button> : <button type="submit" className="w-100 btn  text-white bg-green refer-button cxy mt-4 send-otp" id="send_ottp" onClick={Sendotp}>Send OTP</button>}


                  </Form.Item>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <ToastContainer />
    </>
  );
}
