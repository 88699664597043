import React,{useState,useEffect} from 'react'

export default function Termsandcondition() {
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
     // Simulate loading for 2 seconds
   //   const timer = setTimeout(() => {
       setIsLoading(false);
   //   }, 2000);
 
     // Clear the timer if the component unmounts before 2 seconds
   //   return () => clearTimeout(timer);
   }, []); // Empty dependency array ensures the effect runs once after initial render
 
  return (
   <>
   {isLoading && (
        // <>
        // <div className="loader_roomcode">
        //   <img src={loader} alt="loader" />
        // </div>
        // </>
        <div className="spinner-wrapper">
        <div className="loadernew2"></div>
        </div>  
      )}

    {!isLoading && (
      <section id="Help" className='margin-bottom-88'>
        {/* Your iframe code here */}
        <div className='margin-bottom-88 mb-0'>
          <div className="pb-4">
            <iframe src={`${process.env.REACT_APP_API_URL}/pages/help.php`} style={{ width: '100%', height: '100vh' }} />
          </div>
        </div>
        {/* Rest of your content */}
      </section>
    )}
    </>
   //  <section className="termscondition margin-bottom-88">
   //     <div className='terms'>
   //        <h3>Terms And Condition</h3>
   //        <h4 >रेट 10 के 980</h4>
   //    </div>
   //    <div className='listterms'>
   //        <ul>
   //           <li> डार्क मोड मैं एप्लीकेशन को यूज़ न करे</li>
   //           <li> 10,000 इन्टू की जोड़ी लगेगी मैक्सिमम</li>
   //           <li> 5,000 इन्टू की  हरुफ लगेगी मैक्सिमम</li>
   //           <li> 20,000 इन्टू की  क्रासिंग लगेगी मैक्सिमम</li>
   //           <li> रिजल्ट एप्लीकेशन मैं अपडेट होते ही पैसा अकाउंट मैं आ जायेगा</li>
   //           <li> एप्लीकेशन मैं कभी भी पैसा अपडेट कर सकते है</li>
   //           <li> पैसा निकलने का समय सुबह 8 बजे से रात 2 बजे तक है </li>
   //           <li> विथड्रावल रिक्वेस्ट डालते ही पैसा आपके के अकाउंट मैं 5 से 10 मिनट मैं जायेगा</li>
   //           <li> अगर आपको किसी भी प्रकार की समस्या होती है तो आप बाबा जी को व्हाट्सप्प कर सकते हो </li>
   //           <li> बाबाजी का हेल्पलाइन नंबर 6367529290</li>
   //        </ul>
   //    </div>
   //   </section>
  )
}
