import React, { useState, useEffect } from "react";
import './Login.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Outlet, Link, Await } from "react-router-dom";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Container from 'react-bootstrap/Container';
import Logoimg from '../../assets/img/logo.png';
import downloadd from '../../assets/img/download.png';
import { NumberOutlined, UserOutlined, LockOutlined, PhoneOutlined } from '@ant-design/icons';
import { Col, Row, Form, Input } from 'antd';
import { Spinner } from "react-bootstrap";
import { ArrowRight, EyeSlash, EyeFill } from "react-bootstrap-icons";
import kgfvideo from '../../assets/img/kgfvideo.mp4';



import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPWAInstallProvider, { useReactPWAInstall } from "react-pwa-install";
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import PWAPrompt from 'react-ios-pwa-prompt'
import Prompt from 'react-ios-pwa-prompt';

// import {useNavigate} from 'react-router-dom';

import Whatsapp from '../../assets/img/whatsappicon.png'


function Login() {
    const [browserId, setBrowserId] = useState('');
    const [appmanagerdata, setAppmanager] = useState(null);
    const [helplinenumber, setMinibet] = useState([]);
    const [Maxpoints, setMaxpoints] = useState([]);
    const [loading, setLoading] = useState(false);
    const mobile1 = window.innerWidth <= 768 ? true : false;

    const navigate = useNavigate();




    useEffect(() => {

        let browserIdd = Cookies.get('browserId');

        if (!browserIdd) {
            browserIdd = uuidv4();
            Cookies.set('browserId', browserIdd, { expires: 7 }); // Store the ID in a cookie that expires in 365 days
        }
        setBrowserId(browserIdd)
        // alert(browserIdd);


        // console.log('Browser ID:', browserIdd);
    }, [mobile1]);





    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [showInstallButton, setShowInstallButton] = useState(true);

    var token = localStorage.getItem("userid");

    // useEffect(() => {
    //   if (token) {
    //     window.location.href = '/Home';
    //   }
    // }, [])

    useEffect(() => {
        const userid = localStorage.getItem("userid");
        if (userid) {
            navigate('/');
        }
        window.addEventListener('beforeinstallprompt', (event) => {
            event.preventDefault();
            setDeferredPrompt(event);
        });

        return () => {
            window.removeEventListener('beforeinstallprompt', (event) => {
                event.preventDefault();
                setDeferredPrompt(event);
            });
        };
    }, []);

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);



    useEffect(() => {
        // Check if the app has been installed (stored in local storage)
        const isAppInstalled = localStorage.getItem('isAppInstalled');
        if (isAppInstalled) {
            setShowInstallButton(false);
        }
    }, []);

    const handleInstallClick = () => {
        // alert('fff')
        if (deferredPrompt) {
            deferredPrompt.prompt();

            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');

                    localStorage.setItem('isAppInstalled', 'true');
                    setShowInstallButton(false);
                } else {
                    console.log('User dismissed the A2HS prompt');
                }

                setDeferredPrompt(null);
            });
        }
    };

    const onFinish = (values) => {
        // console.log('Success:', values);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };



    localStorage.setItem("token", "");
    const [user, setUserts] = useState({
        mobile: "",
    });

    const [motp, setMotp] = useState(
        {
            mobile: "9782140792",
            otp: "123456"
        }
    )



    const [mobile, setmobile] = useState("");
    const [password, setPassword] = useState("")

    const onInputChangemobile = (e) => {
        setmobile(e.target.value);
    };

    const onInputChangepassword = (e) => {
        setPassword(e.target.value);
    };

    const Submit = async () => {
        const devid = localStorage.getItem("dev_id");
        const user_id = localStorage.getItem("userid");



        console.log("jh")

        try {
            setLoading(true);

            let url = `${process.env.REACT_APP_API_URL}/POM_login.php`;

            const formData = new FormData();
            formData.append('mobileNum', mobile);
            formData.append('pss', password);
            formData.append('userid', user_id);
            formData.append('dev_id', browserId);

            formData.append('app_id', process.env.REACT_APP_API_ID);
            // formData.append('device_id', 'hello');
            const config = {
                method: 'POST',
                url: url,
                data: formData,
            };

            axios(config)
                .then(function (response) {

                    if (response.data.success == 1) {
                        console.warn(response.data.success)
                        Swal.fire({
                            icon: 'success',
                            title: 'Success!',
                            text: 'Login  successfully.',
                            timer: 2000
                        }).then((result) => {
                            navigate('/');
                            localStorage.setItem("userid", response.data.user_id);
                            localStorage.setItem('dev_id', response.data.device_id);
                            sessionStorage.getItem(response.data.user_id);
                            console.warn(response.data.user_id);
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Login Failed Please try again !',
                            text: response.data.message,
                            timer: 2000
                        })
                    }
                })
                .finally(() => {
                    // setTimeout(() =>{
                    setLoading(false);
                    // },5000);
                });

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setLoading(false);
        }

    }



    const app_manager = async () => {


        let url = (`${process.env.REACT_APP_API_URL}/POM_help_number.php`);
        const formData = new FormData();
        formData.append('app_id', process.env.REACT_APP_API_ID);

        var config = {
            method: 'POST',
            url: url,
            body: formData,
        };
        axios.post(url, formData, config)
            .then(function (response) {

                const res = JSON.stringify(response.data.data);
                const objectRes = JSON.parse(res);
                setAppmanager(objectRes);
                var help_line_number = objectRes.help_line_number;
                console.warn(help_line_number);
                setMinibet(help_line_number)

                // setIsLoading(false);

                console.warn(objectRes);

            })

    }
    useEffect(() => {
        app_manager();
    }, [])


    const [showVideo, setShowVideo] = useState(false);

    const handlePlayClick = () => {
        // Show the video in fullscreen when the play button is clicked
        setShowVideo(true);
    };

    const handleVideoClose = () => {
        // Close the fullscreen video
        setShowVideo(false);
    };






    return (
        <>
            <div className="Loginpage">



                <div className="logobgshape d-flex justify-content-center align-items-center ">
                    <div className="logo ">
                        <img src={Logoimg} width="150" />

                    </div>
                </div>


                <div className="loginform">
                    <div className="bg-white login-form">
                        <h3 className="mb-0 fw-bold text-center text-secondary">Welcome Back</h3>
                        <div className="w-100 d-flex justify-content-center">

                            <p className="text-center mb-0 pleaselogin me-2 helpline">Helpline Number:</p>
                            <PhoneOutlined className="call-icon" />
                            <p className="text-center mb-0 pleaselogin">{helplinenumber}</p>
                        </div>


                        <div className="d-flex justify-content-center">
                            {!showVideo && (
                                <button
                                    className="btn btn-ronded bg-qwe p-4  justify-content-center"
                                    style={{ marginRight: 7 }}
                                    onClick={handlePlayClick}
                                >
                                    <span className="text-white mb-1 d-block">
                                        डाउनलोड करने के लिए वीडियो देखें
                                    </span>
                                    <span className="text-danger playbtn_custum rounded-circle bg-warning p-2">
                                        <i className="bi bi-play-fill" />
                                    </span>
                                </button>
                            )}
                            {showVideo && (
                                <div className="fullscreen-video-container">
                                    <video controls autoPlay className="fullscreen-video">
                                        <source src={kgfvideo} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    <button className="close-btn" onClick={handleVideoClose}>
                                        Close
                                    </button>
                                </div>
                            )}
                        </div>
                        <Row justify={'center'}>
                            <Col xs={24} xl={8}>
                                <div className="form-login-design">
                                    <Form
                                        name="basic"
                                        labelCol={{
                                            span: 8,
                                        }}
                                        wrapperCol={{
                                            span: 24,
                                        }}
                                        style={{
                                            maxWidth: 600,
                                        }}
                                        initialValues={{
                                            remember: true,
                                        }}
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                        autoComplete="off"
                                    >
                                        <Row justify={'center'}>
                                            <Col span={24} xl={24} lg={24} md={24} sm={24}>
                                                <Form.Item
                                                    name="username" prefix={<UserOutlined className="site-form-item-icon" />}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your Number!',
                                                        },
                                                    ]}
                                                >
                                                    <div className="w-100">
                                                        <Input className="username w-100" name="mobileNum"
                                                            maxLength={10} placeholder="Mobile Number" value={mobile} onChange={onInputChangemobile}
                                                            prefix={<UserOutlined className="site-form-item-icon" style={{ padding: "9px" }} />}
                                                        />
                                                        {/* {isValidMobile ? <p>Valid mobile number!</p> : <p>Invalid mobile number</p>} */}

                                                    </div>
                                                </Form.Item>
                                            </Col>


                                            <Col span={24} xl={24} md={24}>
                                                <Form.Item
                                                    name="password"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your Password!',
                                                        },
                                                    ]}
                                                >
                                                    <Input className="username w-100"
                                                        prefix={<LockOutlined className="site-form-item-icon" />}
                                                        type="password" style={{ padding: "9px" }}
                                                        placeholder="Password"
                                                        value={password}
                                                        onChange={onInputChangepassword}
                                                    />
                                                </Form.Item>
                                            </Col>

                                        </Row>


                                        <Form.Item>
                                            <button disabled={loading} type="button" className="d-block w-100 text-white bg-green refer-button cxy otp_login submit_data btn w-80 d-flex justify-content-around" id="submit_data" name="submit_data" onClick={Submit}>LOGIN
                                                {loading && <Spinner animation="border" />}
                                                <ArrowRight className="fs-3 fw-bold" />
                                            </button>


                                        </Form.Item>
                                        <Form.Item className="text-center mb-0 mt-2">
                                            <Link className="link btn text-center register1" to="/Register">REGISTER NOW</Link>


                                        </Form.Item>



                                    </Form>



                                    <div className="d-flex justify-content-center mt-4">
                                        {isIOS && (
                                            <>
                                                <p> To add this app to your home screen, tap the share icon below and select "Add to Home Screen."</p>

                                                    // Do something when the user clicks the "Add to Home Screen" button
                                                <button className="btn btn-ronded text-light">Install Web Application Ios</button>

                                            </>

                                        )}
                                        {!isIOS && showInstallButton && (
                                            <button className="btn " onClick={handleInstallClick}>
                                                <img src={downloadd} alt="downloadd"  width={120}/>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </div>
                </div>
            </div >
            <Prompt />
            <PWAPrompt />
        </>

    );
}

export default Login;


