
import React, { useState, useEffect } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { Carousel } from 'antd';
import axios from 'axios';
import { Button } from 'react-bootstrap'
import referimg from '../assets/img/refer.jpg'
import Ruppe from '../assets/img/Ruppes.png'
import jQuery from 'jquery';
import ReactHtmlParser from 'react-html-parser';
import logo from '../assets/img/logo.png';


export default function Home() {
  const initialTime = 15 * 60; // 15 minutes in seconds
  const [countdown, setCountdown] = useState(initialTime);
  const [isRunning, setIsRunning] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersdata, setUsersdata] = useState([]);
  const [userData, setUserData] = useState(null);
  // const [userData, setUserData] = useState(null);
  const user_id = localStorage.getItem("userid");
  const devid = localStorage.getItem("dev_id");
  const [userss, setUserss] = useState([]);
  const [formattedDate, setFormattedDate] = useState('');
  const [Refcode, setRefcode] = useState('');
  const [users1, setUsers1] = useState([]);

  // alert(user_id)

  useEffect(() => {
    const user_id = localStorage.getItem("userid");
    if (user_id == null) {
      window.location.href = '/login';
    };
    if (devid == null) {
      window.location.href = '/login';
    }
    loaduser();
  }, [])
  const loaduser = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    // alert(dev_id);
    let url = (`${process.env.REACT_APP_API_URL}/POM_get_user_credit.php`);
    // console.warn(url);
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    formData.append('dev_id', dev_id);
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        setUserss(objectRes);
        console.warn(objectRes);

      })
  }
  useEffect(() => {

    loaduser1();
  }, [])

  useEffect(() => {
    let timer;
    if (isRunning && countdown > 0) {
      timer = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      clearInterval(timer);
    }
    return () => {
      clearInterval(timer);
    };
  }, [isRunning, countdown]);

  const startCountdown = () => {
    setIsRunning(true);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  useEffect(() => {
    loadUser();
    loaduser1();
    BroadCast()
  }, []);

  useEffect(() => {
    // Get the current date
    const currentDate = new Date();

    // Format the date as DD_MM_YY
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDate.getFullYear().toString().slice(-4);

    // Set the formatted date
    setFormattedDate(`${day}-${month}-${year}`);
  }, []);
  const loaduser1 = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    // alert(dev_id);
    let url = (`${process.env.REACT_APP_API_URL}/POM_get_user_profile.php`);
    // console.warn(url);
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    formData.append('dev_id', dev_id);
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const refcode = response.data.refCode;
        setRefcode(refcode)
        // alert(res)
        const objectRes = JSON.parse(res);
        setUserData(objectRes);
        console.warn(objectRes);
      })
  }
  const loadUser = async () => {
    try {
      const user_id = localStorage.getItem('userid');
      const devid = localStorage.getItem('dev_id');

      let url = `${process.env.REACT_APP_API_URL}/POM_home.php`;
      const formData = new FormData();
      formData.append('app_id', process.env.REACT_APP_API_ID);
      formData.append('user_id', user_id);
      formData.append('dev_id', devid);

      const response = await axios.post(url, formData);
      const objectRes = response.data;
      const dddddd = response.data.current_date;
      console.warn(dddddd);
      setUsers(objectRes);


      if (objectRes.data) {
        setUsersdata(objectRes.data);
      } else {
        setUsersdata([]);
      }
    } catch (error) {
      console.error('Error loading user data:', error);
    }
  };
  const BroadCast = async () => {
    const user_id = localStorage.getItem("userid");
    // alert(user_id);
    try {
      let url = (`${process.env.REACT_APP_API_URL}/POM_app_notice.php`);
      // console.warn(url);
      const formData = new FormData();
      formData.append('app_id', process.env.REACT_APP_API_ID);
      formData.append('user_id', user_id);
      var config = {
        method: 'POST',
        url: url,
        body: formData,
      };
      axios.post(url, formData, config)
        .then(function (response) {
          const res = JSON.stringify(response.data.data);
          const objectRes = JSON.parse(res);
          // const objectRes1 = JSON.parse(res.is_display);
          // console.warn(objectRes1)
          setUsers1(objectRes[0]);
          // if (objectRes.is_display == 1) {
          //   // alert('dd')
          //   jQuery('#modal-container').removeAttr('class').addClass("five");
          //   jQuery('body').addClass('modal-active');
          // }
          // localStorage.setItem("notificationCount", objectRes.length);
        })

    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  }
  const handleClick = (id, name) => {
    // localStorage.setItem('name1',users.name);
    // console.warn(users.name);
    window.location.href = `/Playgame?id=${id}&name=${name}`;
  };
  jQuery(function () {
    jQuery('#modal-container').removeAttr('class').addClass("five");
    jQuery('body').addClass('modal-active');
    jQuery('#clickme').click(function () {
      var buttonId = jQuery(this).attr('id');
      jQuery('#modal-container').removeAttr('class').removeClass(buttonId);
      jQuery('body').removeClass('modal-active');

      // jQuery(this).addClass('out');
    });
    jQuery('#closemodle').click(function () {
      var buttonId = jQuery(this).attr('id');
      jQuery('#modal-container').removeAttr('class').removeClass(buttonId);
      jQuery('body').removeClass('modal-active');

      // jQuery(this).addClass('out');
    });
  });
  // State to hold the status message


  // Name of the cache to delete
 
  return (
    <>
      {users1 && users1.is_display == 1 ?
        <div className="popupnew" id="modal-container">
          <div className='new_five'>
            <div className="modal-background position-relative">
              <div className="modal">
                <button id="closemodle">&times;</button>
                <div className='logo_popup'>
                  <img src={logo} style={{ width: '70px' }} alt="hello" />
                </div>
                <h2 className='updateversion'>{users1 && users1.title}</h2>
                <p className='updateversion_sec'>{ReactHtmlParser(users1 && users1.description)}</p>
                {/* <div className="btn-group">

                <Link to="" className="btnnew btn-masterful" id="clickme">
                  <span className="icon">&#x1F680;</span>
                  <span  className="btn-txt">Click me!</span>
                </Link>
              </div> */}

              </div>
            </div>
          </div>
        </div>
        :
        ''
      }
      <div id="five" className="button"></div>
      <div className="mainhome">

        <div className="bg_home margin-bottom-88">


          {/* {users && (
            <marquee className="resultmarquee" behavior="" direction="">{users.marquee_msg}</marquee>
          )} */}
          <div className="card card-style cardbabaji">
            <div className="content">
              {userData && (
                <center>
                  {/* <h6>&#128293;  भरोसे का एक ही नाम &#128293; </h6> */}
                  <h4 className='text-transform-uppercase'>Name : {userData.name} </h4>
                  <h6 className='text-transform-uppercase'>REFFER Id : {Refcode} </h6>
                </center>
              )}
              {/* <h6 className="d-flex justify-content-center">

              </h6> */}
            </div>
          </div>
          {userData && userData.is_playstore == 0 ?
            <div className="mt-2" style={{ textAlign: "center" }}>
              {/* {Ruppe} */}
              {/* <img src={Ruppe} className='img-fluid rounded' /> */}

              <Link to="/Wallet"
                type="button" className="btnbal w-75 p-2 balance_btn border-0">
                <div className='whatsapp_log d-inline-block me-2'>
                  <img src={Ruppe} className='img-fluid rounded' />
                </div>
                BALANCE : <span className='color_result fs-5'>{userss.credit}</span></Link>
            </div>
            :
            <></>
          }


          <div>
            <div class="animated-box in mt-2">
              <div className="card card-style">
                <center>
                  {users && (
                    <div className="result resultmarquee">
                      <h6>{users.current_market_details && users.current_market_details.market_name}</h6>
                      <div className='result'>{users.current_market_details && users.current_market_details.market_result}</div>
                      <div className='result_time'>{users.current_market_details && users.current_market_details.market_result_time}</div>
                      <div className='result_date'>{users.current_market_details && users.current_market_details.updated_date}</div>
                    </div>
                  )}
                </center>
              </div>
            </div>
          </div>
          {userData && userData.is_playstore == 0 ?
            <div className="card text-center clickresult animated-box">
              <Link to='/ReferCode' className='d-block btnbal refer-button Earn border-0 btn btn-primary'>
                {/* <div className='whatsapp_log d-inline-block me-2'>
                  <img src={referimg} className='img-fluid rounded' />
                </div> */}
                शेयर Share & Earn</Link>
              {/* <p className='mb-0'>सबसे पहले रिजल्ट देखने के लिए क्लिक करे</p> */}
              {/* <Link to="https://www.babajiisatta.com" className='clicklink'  id="neonShadow">Click Link</Link> */}
            </div>
            :
            <></>
          }
          {/* <div className="card live-result">
            <p><strong>Note</strong></p>
            <p>{ReactHtmlParser(users.note)}</p>
          </div> */}
          <div className="card live-result">
            <p>
              Live Result of {formattedDate}</p>
          </div>
          <table className='marketnametime mt-2'>
            <thead>
              <tr>
                <td className='width_50'>Market Name/Time</td>
                <td className='width_25'>Previous Result</td>
                <td className='width_25'>Today Result</td>
              </tr>
            </thead>
          </table>
          {usersdata && usersdata.map((user) => (

            <div className="market">
              <div className="d-flex justify-content-between align-items-center">

                <div className="marketnamelist width_60">
                  <h3 className="animationtittle markettitlename">{user.market_name}</h3>
                  {/* <h3 className="animationtittle markettitlename">{user.market_name}</h3> */}

                  <ul className="liststyle">
                    {/* <li>{user.open_time}</li>
                    <li>|</li>
                    <li>{user.result_time}</li> */}
                    <li>Result At</li>
                    <li>{user.market_result_time}</li>
                  </ul>

                </div>

                <div className="d-flex width_40">
                  <div className="text-center " onClick={startCountdown} disabled={isRunning}>
                    {/* <Icon.ChevronRight /> */}
                    <h3 className='mb-0 color_result'>{user.market_result_previous_day}</h3>

                  </div>
                  &nbsp; &nbsp; &nbsp;
                  <div className="text-center" onClick={startCountdown} disabled={isRunning}>
                    {/* <Icon.ChevronRight /> */}
                    <h3 className='mb-0 text'>{user.market_result}</h3>

                  </div>
                </div>
                {/* <div className="text-center playicon" onClick={startCountdown} disabled={isRunning}>
                  <Icon.ChevronRight />

                </div> */}
              </div>
            </div>

          ))}
        </div>
      </div>

    </>
  );
}


