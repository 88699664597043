import React, { useState, useEffect } from 'react'
import { Container, Button } from 'react-bootstrap'
// import userprofile from '../../assets/img/logo.png'
import userprofile from '../../assets/img/logo.png'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from "axios";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Col, Row, Form, Input } from 'antd';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function Profile() {

  const navigate = useNavigate();

  const [useName, useNameUpdate] = useState('');
  const [mobnumber, setMobnumber] = useState('');
  const [dateOfBt, dateOfBtUpdate] = useState('');
  const [names, setNeme] = useState('');
  const user_id = localStorage.getItem("userid");
  const [users, setUsers] = useState([]);

  const devid = localStorage.getItem("dev_id");
  const OnInputChange = (newValue) => {
    useNameUpdate(newValue);
  };
  const handleInputChange = (e) => {
    const newValue = e.target.value;
    OnInputChange(newValue);

    // Check if a new value is entered and clear names
    if (newValue !== names) {
      setNeme('');
    }
  };
  const OnIumberInputChange = (e) => {
    const newName = e.target.value;
    setMobnumber(newName);
  };

  const OnDobInputChange = (e) => {
    const newDate = e.target.value;
    dateOfBtUpdate(newDate);
  };


  useEffect(() => {
    loaduser1();
  }, [])

  const [userData, setUserData] = useState();

  const onFinish = (values) => {

  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const loaduser1 = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    // alert(dev_id);
    let url = (`${process.env.REACT_APP_API_URL}/POM_get_user_profile.php`);
    // console.warn(url);
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    formData.append('dev_id', dev_id);
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const namee = response.data.name;
        setNeme(namee)
        // alert(res)
        const objectRes = JSON.parse(res);
        setUserData(objectRes.name);
        console.warn(setUserData)
      })
  }

  const [loading, setLoading] = useState(false);
  const updateUsername = async () => {
    // setLoading(true);
    try {
      const formData = new FormData();
      formData.append('name', useName || names);
      formData.append('dob', dateOfBt);
      formData.append('app_id', process.env.REACT_APP_API_ID);
      formData.append('user_id', user_id);

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/POM_update_profile.php`, formData);

      if (response.data.message === 'Updated success') {

        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Username updated successfully.',
          timer: 2000
        }).then((result) => {
          navigate('/');
        })

      } else {

        toast.error('Failed to update . Please try again later.');
      }
    } catch (error) {
      console.error('User Name Update:', error);
      toast.error('An error occurred while updating username. Please try again later.');
    }
  };




  useEffect(() => {
    const user_id = localStorage.getItem("userid");
    if (user_id == null) {
      window.location.href = '/';
    };
    if (devid == null) {
      window.location.href = '/';
    }
    loaduser();
  }, [])
  const loaduser = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    // alert(dev_id);
    let url = (`${process.env.REACT_APP_API_URL}/POM_get_user_credit.php`);
    // console.warn(url);
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    formData.append('dev_id', dev_id);
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        setUsers(objectRes);
        console.warn(objectRes);

      })
  }


  const totalBonus = users.total_bonus ?? 0;
  return (
    <>
      <section id="profile" /*className='margin-bottom-88'*/>
        <Container fluid className="p-0">
          <div className="profilepage">

            <div className="profile">
              <img src={userprofile} className='img-fluid' />
            </div>

          </div>
          <div className="profiledetails">
            <div className="cardstyle">
              <div className="d-flex justify-content-between mb-2 balancebonus">
                <div className="mt-2">
                  <Button type="button" className="btnbal bg-success W-50">BALANCE : {users.credit}</Button>
                </div>
                <div className="  mt-2">
                  <Button type="button" className="btnbal bg-success W-50">BONUS : {totalBonus}</Button>
                </div>
              </div>
              <Form
                name="basic"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                style={{
                  maxWidth: 600,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Row>
                  <Col span={24} xl={24} md={24}>
                    <Form.Item

                      name="username" prefix={<UserOutlined className="site-form-item-icon" />}
                      rules={[
                        {
                          required: true,
                          message: 'Please input your Number!',
                        },
                      ]}
                    >
                      <div>
                        <Input
                          className="username px-2"
                          name="name"
                          placeholder="Name"
                          value={useName || names}
                          defaultValue={userData}
                          onChange={handleInputChange}
                        />


                      </div>
                    </Form.Item>
                  </Col>
                  <Col span={24} xl={24} md={24}>
                    <Form.Item

                      name="username" prefix={<UserOutlined className="site-form-item-icon" />}
                      rules={[
                        {
                          required: true,
                          message: 'Please input your Date of Birth!',
                        },
                      ]}
                    >
                      <div>
                        <Input className="username px-2"
                          name="dob"
                          placeholder="DOB"
                          type="date"
                          id="birthday"
                          value={dateOfBt}
                          onChange={OnDobInputChange} />


                      </div>
                    </Form.Item>
                  </Col>


                  <Col span={24} xl={24} md={24}>
                    <Form.Item
                      wrapperCol={{

                        span: 16,
                      }}
                    >
                      <div className="submitbtn">
                        <Button type="button" className="submitbtn" onClick={updateUsername}>Submit</Button>
                        <h1>{ }</h1>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <ToastContainer />
            </div>
          </div>
        </Container>
      </section>
      {loading && (

        <div className="spinner-wrapper">

          <div className="spinner">
            <div>
              <div className="mesh-loader">
                <div className="set-one">
                  <div className="circle"></div>
                  <div className="circle"></div>
                </div>

                <div className="set-two">
                  <div className="circle"></div>
                  <div className="circle"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
